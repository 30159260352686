import { useEventListener } from "hooks/useEventListener";

/**
 * @param {{ current: HTMLElement | null }} ref
 * @param {() => void} callback
 */
export function useFocusOut(ref, callback) {
  useEventListener("focusout", (e) => {
    const isGatsbyWrapper =
      e.relatedTarget instanceof HTMLElement &&
      e.relatedTarget.id === "gatsby-focus-wrapper";
    const isFixrModal = Array.from(
      document.querySelectorAll(".fixr-modal")
    ).some(
      (modal) =>
        e.relatedTarget instanceof Node && modal.contains(e.relatedTarget)
    );
    const contained =
      e.relatedTarget instanceof Node && ref.current.contains(e.relatedTarget);
    if (e.relatedTarget && !isGatsbyWrapper && !isFixrModal && !contained) {
      callback();
    }
  });
}
