import React, { useEffect, useState } from "react";
import { store } from "stores/store";
import { Dropdown } from "./Dropdown";
import { TicketShop } from "./TicketShop";

const ticketshops = {
  general: {
    nameFilter: "",
    script: (theme = "dark") =>
      `<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-fixr-shop-id="003b9505-1656-437c-9e3e-ce6bd853a135" data-fixr-theme="${theme}"></script>`,
  },
  booth8: {
    nameFilter: "booth of 8",
    script: (theme = "dark") =>
      `<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-name-filter="booth 8" data-fixr-shop-id="ae2c6573-8c3e-4b5d-bf45-3370ea6aac66" data-fixr-theme="${theme}"></script>`,
  },
  booth12: {
    nameFilter: "booth of 12",
    script: (theme = "dark") =>
      `<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-name-filter="booth 12" data-fixr-shop-id="0e47faf3-fbe2-4e20-adbd-eca3f277a3b6" data-fixr-theme=${theme}></script>`,
  },
  cocktailBooth: {
    nameFilter: "cocktail booth",
    script: (theme = "dark") =>
      `<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-name-filter="cocktail booth" data-fixr-shop-id="6b5a3824-fb57-4d43-a1b8-d48062580c40" data-fixr-theme="${theme}"></script>`,
  },
};

const options = [
  {
    label: "SELECT TICKET TYPE",
    value: "",
  },
  {
    label: "GENERAL ADMISSION",
    value: "general",
  },
  {
    label: "Tiger and Zebra Booths (8 people)",
    value: `booth8`,
  },
  {
    label: "Leopard Booth (12 people)",
    value: "booth12",
  },
  {
    label: "Cocktail Booth (6 People)",
    value: "cocktailBooth",
  },
];

export function MultiTicketShop({ theme = "dark" }) {
  // const [value, setValue] = useState(store.preferredTicketShop);

  // useEffect(() => {
  //   setValue(store.preferredTicketShop);
  // }, [store.preferredTicketShop]);

  return (
    <div>
      <h2>Buy Tickets</h2>
      {/* <Dropdown
        value={value}
        id="ticketType"
        options={options}
        hideFalsy
        onChange={(option) => {
          setValue(option.value);
        }}
      /> */}
      <div className="header__ticketshop">
        <TicketShop scriptString={ticketshops.general.script(theme)} />
      </div>
    </div>
  );
}
