import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useEventListener } from "hooks/useEventListener";
import { useFocusOut } from "hooks/useFocusOut";
import React, { useEffect, useRef, useState } from "react";
import { store } from "stores/store";
import IconCross from "../svgs/IconCross.svg";
import IconMenu from "../svgs/IconMenu.svg";
import { MultiTicketShop } from "./MultiTicketShop";

const desktopLinks = [
  { to: "/", children: "Home" },
  { to: "/gallery", children: "Gallery" },
  { to: "/about", children: "About Us" },
  { to: "/private-hire", children: "Private Hire" },
  { to: "/contact", children: "Contact" },
  { to: "/vip", children: "VIP" },
];

const mobileLinks = [
  ...desktopLinks,
  { to: "/events", children: "Buy Tickets" },
];

export function Header() {
  const { pathname } = useLocation();
  const [navIsOpen, setNavIsOpen] = useState(false);
  useEffect(() => setNavIsOpen(false), [pathname]);

  const headerRef = useRef(null);
  useFocusOut(headerRef, () => setNavIsOpen(false));

  const sidebarRef = useRef(null);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  useEffect(() => {
    store.openSidebar = () => setSidebarIsOpen(true);
    return () => {
      store.openSidebar = () => {};
    };
  }, []);

  useEffect(() => {
    if (!sidebarIsOpen) return;
    const timeout = setTimeout(
      () =>
        sidebarRef.current
          ?.querySelector('.header__sidebar-content > div[aria-hidden="false"]')
          ?.querySelector("a, button")
          ?.focus(),
      400 // Wait for animation to finish
    );
    return () => clearTimeout(timeout);
  }, [sidebarIsOpen]);

  useEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setNavIsOpen(false);
      setSidebarIsOpen(false);
    }
  });

  useEventListener("mousedown", (e) => {
    const isFixrModal = Array.from(
      document.querySelectorAll(".fixr-modal")
    ).some((modal) => e.target instanceof Node && modal.contains(e.target));
    if (!isFixrModal && !sidebarRef.current.contains(e.target)) {
      setSidebarIsOpen(false);
    }
  });

  useFocusOut(sidebarRef, () => setSidebarIsOpen(false));

  return (
    <header ref={headerRef} id="header" data-open={navIsOpen}>
      <div className="header__content">
        <Link to="/">
          <StaticImage
            className="header__logo"
            src="../images/logo.png"
            alt="Plush Oxford logo"
          />
        </Link>
        <nav className="header__nav-desktop">
          {desktopLinks.map((link) => (
            <Link key={link.to} to={link.to}>
              {link.children}
            </Link>
          ))}
        </nav>
        <button
          className="header__toggle_sidebar"
          onClick={() => setSidebarIsOpen(true)}
          aria-expanded={sidebarIsOpen}
          aria-controls="header__sidebar"
        >
          Buy Tickets
        </button>
        <button
          className="header__toggle_nav"
          onClick={() => setNavIsOpen(!navIsOpen)}
          aria-expanded={navIsOpen}
          aria-controls="header"
        >
          {navIsOpen ? <IconCross /> : <IconMenu />}
        </button>
      </div>
      <nav className="header__nav-mobile">
        {mobileLinks.map((link) => (
          <Link key={link.to} to={link.to} tabIndex={navIsOpen ? 0 : -1}>
            {link.children}
          </Link>
        ))}
      </nav>
      <aside id="header__sidebar" role="dialog" aria-hidden={!sidebarIsOpen}>
        <div className="header__sidebar-wrapper">
          <div ref={sidebarRef} className="header__sidebar-content">
            <MultiTicketShop />
          </div>
        </div>
      </aside>
    </header>
  );
}
