import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

export default function Layout({ children, ...props }) {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
