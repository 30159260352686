import { useEffect, useRef } from "react";

/** @type {typeof window.addEventListener} */
export const useEventListener = (type, listener) => {
  const ref = useRef(listener);
  ref.current = listener;

  useEffect(() => {
    const listener = (e) => ref.current(e);
    window.addEventListener(type, listener);
    return () => window.removeEventListener(type, listener);
  }, [type]);
};
